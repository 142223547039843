import React, { useState, useEffect } from 'react';
import { Box, Container, Input, Stack, Text, Button, useToast } from '@chakra-ui/react';
import { states, Roles, genres } from '../../utils/consts';
import axios from 'axios';
import { auth } from "../../utils/firebaseConfig";

interface PushNotificationAdminProps { }

const PushNotificationAdmin: React.FC<PushNotificationAdminProps> = () => {
    const toast = useToast();
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [selectedStates, setSelectedStates] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
    const [sendToAllUsers, setSendToAllUsers] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const isEnabled = title.trim() && body.trim() && (
            sendToAllUsers ||
            (selectedStates.length > 0 || selectedRoles.length > 0 || selectedGenres.length > 0)
        );

        setIsButtonDisabled(!isEnabled);
    }, [title, body, selectedStates, selectedRoles, selectedGenres, sendToAllUsers]);

    const sendNotification = async () => {
        try {
            // Construct confirmation message
            let confirmationMessage = 'Are you sure you want to send the notification with the following details?\n\n';
            confirmationMessage += `Title: ${title}\n`;
            confirmationMessage += `Body: ${body}\n`;
            confirmationMessage += selectedStates.length ? `State: ${selectedStates.join(', ')}\n` : '';
            confirmationMessage += selectedRoles.length ? `Role: ${selectedRoles.join(', ')}\n` : '';
            confirmationMessage += selectedGenres.length ? `Genre: ${selectedGenres.join(', ')}\n` : '';

            const confirmSend = window.confirm(confirmationMessage);
            if (!confirmSend) {
                return;
            }

            const payload = {
                body: body,
                title: title,
                ...(selectedStates.length && { states: selectedStates }),
                ...(selectedRoles.length && { roles: selectedRoles }),
                ...(selectedGenres.length && { genres: selectedGenres })
            };

            const token = await auth.currentUser?.getIdToken();
            const url = sendToAllUsers ? 'notifications/send-to-all-users' : 'notifications/send-to-users';
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${url}`, payload, {
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                showToast("Notification sent successfully!", "success");
                setTitle('');
                setBody('');
                setSelectedStates([]);
                setSelectedRoles([]);
                setSelectedGenres([]);
                setSendToAllUsers(false);
            } else {
                showToast("Failed to send notification", "error");
            }
        } catch (error) {
            console.error('Error sending notification:', error);
            showToast("Error sending notification. Please try again later.", "error");
        }
    };

    const showToast = (title: string, status: "success" | "error", position: "top" | "bottom" = "top") => {
        toast({
            title: title,
            status: status,
            duration: 5000,
            isClosable: true,
            position: position,
        });
    };

    return (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Container maxW="50%" textAlign="center">
                <Box>
                    <Input variant="flushed" placeholder='Title' mb={10} mt={10} value={title} onChange={(e) => setTitle(e.target.value)} />
                    <Input variant="flushed" placeholder='Body' mb={10} value={body} onChange={(e) => setBody(e.target.value)} />
                    <Box display="flex" alignItems="center" mb={10}>
                        <input
                            type="checkbox"
                            checked={sendToAllUsers}
                            onChange={(e) => setSendToAllUsers(e.target.checked)}
                        />
                        <Text ml={2}>Send to all users</Text>
                    </Box>
                    {!sendToAllUsers &&
                        <Stack spacing={6} direction={{ base: 'column', md: 'row' }} justify="center">
                            <Box>
                                <Text>States:</Text>
                                <select
                                    multiple // Allow multiple selection
                                    value={selectedStates}
                                    onChange={(e) => setSelectedStates(Array.from(e.target.selectedOptions, (item) => item.value))}
                                    style={{ backgroundColor: 'white', color: 'black', padding: '8px', borderRadius: '5px', width: '100%' }}
                                >
                                    {states.map((state, index) => (
                                        <option key={index} value={state}>{state}</option>
                                    ))}
                                </select>
                            </Box>
                            <Box>
                                <Text>Roles:</Text>
                                <select
                                    multiple
                                    value={selectedRoles}
                                    onChange={(e) => setSelectedRoles(Array.from(e.target.selectedOptions, (item) => item.value))}
                                    style={{ backgroundColor: 'white', color: 'black', padding: '8px', borderRadius: '5px', width: '100%' }}
                                >
                                    {Roles.map((role, index) => (
                                        <option key={index} value={role}>{role}</option>
                                    ))}
                                </select>
                            </Box>
                            <Box>
                                <Text>Genres:</Text>
                                <select
                                    multiple
                                    value={selectedGenres}
                                    onChange={(e) => setSelectedGenres(Array.from(e.target.selectedOptions, (item) => item.value))}
                                    style={{ backgroundColor: 'white', color: 'black', padding: '8px', borderRadius: '5px', width: '100%' }}
                                >
                                    {genres.map((genre, index) => (
                                        <option key={index} value={genre}>{genre}</option>
                                    ))}
                                </select>
                            </Box>
                        </Stack>
                    }
                    <Button
                        colorScheme={isButtonDisabled ? "gray" : "blue"}
                        opacity={isButtonDisabled ? 0.6 : 1}
                        cursor={isButtonDisabled ? "not-allowed" : "pointer"}
                        mt={20}
                        onClick={sendNotification}
                        disabled={isButtonDisabled}>
                        Send Notification
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default PushNotificationAdmin;
